<template>
  <el-dialog
      title="Product Details"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose"
  >
    <span style="color: #E6A23C;margin-bottom: 10px;    display: inline-block;">Click the image to view a larger image and other galleries</span>
    <el-container style="border: 1px solid #eee">
      <!--左侧图片数据-->
      <el-aside width="50%">
        <div class="demo-image__lazy">

        </div>
        <el-image
            v-for="url in productInfo.images" :key="url" :src="url" lazy
            class="proImg"
            :preview-src-list="productInfo.images">
        </el-image>
      </el-aside>
      <el-container>
        <el-main>
          <!-- 商品信息 -->
          <div class="proBox">
            <div style="margin-bottom: 20px">
              <span class="proName">{{ productInfo.name }}</span>
              <span style="margin-left: 20px">{{productInfo.code}}</span>
            </div>
            <div class="proDescription">{{ productInfo.describe }}</div>
            <div class="price">
              <div class="profTitle">Quantity Break</div>
              <el-table
                  :data="productInfo.price_data">
                <el-table-column label="Quantity">
                  <template slot-scope="scope">{{ scope.row.quantity }}+</template>
                </el-table-column>
                <el-table-column label="Price">
                  <template slot-scope="scope">{{currency_symbol}}{{ scope.row.delivered_price }}</template>
                </el-table-column>
              </el-table>
            </div>
            <div v-if="productInfo.material_st">
              <div class="profTitle">METERIAL</div>
              <div class="proContent">{{ productInfo.material_str }}</div>
            </div>
            <div v-if="productInfo.imprint_str">
              <div class="profTitle">PRINTING MODE</div>
              <div class="proContent">{{ productInfo.imprint_str }}</div>
            </div>
            <div v-if="productInfo.imprint_area">
              <div class="profTitle">PRINTING SIZE</div>
              <div class="proContent">{{ productInfo.imprint_area }}</div>
            </div>
            <div v-if="productInfo.color">
              <div class="profTitle">COLOR</div>
              <div class="proContent">{{ productInfo.color }}</div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <div class="xtx-goods-page" style="margin-bottom: 100px; margin-top: 30px">
      <div class="container">

      </div>
    </div>
  </el-dialog>
</template>

<script>
import { productDetails } from "@/api/product";
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      productInfo: {},
      currency_symbol:'$',
      // url: require("./110517_1.webp"),
      num: "",
    };
  },
  mounted() {},
  computed: {},
  methods: {
    cellStyle({ row, column, rowIndex, columnIndex }) {
      // console.log(row, column, rowIndex, columnIndex);
      if (columnIndex == 2) {
        // 返回样式对象
        return { backgroundColor: "#c5e8c8" };
      }
      // 或者返回空对象，表示不应用任何样式
      return {};
    },
    handleChange(value) {
      // console.log(value);
    },
  },
};
</script>
<style scoped lang='scss'>
.proImg {width: 200px;height: 200px;margin-left: 5px;margin-bottom: 5px}
.proBox {padding: 15px}
.proName {font-size: 28px;font-style: normal;font-weight: 400;}
.proDescription {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.87);
}
.material {
  clear: both;
  margin-top: 20px;
}
.price {
  margin-top: 10px;
}
.profTitle {
  font-weight: bold;
  margin: 10px 0px;
}
.proContent {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.87);
}
</style>
<style>
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>
<style scoped>
@media screen and (min-width: 1600px) {
  .el-col-lg-12 {
    width: 50%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .el-col-lg-12 {
    /* width: 33.3%; */
  }
}
@media screen and (max-width: 800px) {
  .el-col-lg-12 {
    width: 100%;
  }
}
::v-deep .el-dialog{
  margin-top: 10px !important;
}
@media only screen and (min-width: 1025px) {
  .el-container{
    display: flex;
  }
}
@media only screen and (min-width: 769px ) and (max-width: 1024px){
  .el-container{
    display: block;
    padding: 0 5px;
  }
  ::v-deep .el-dialog {
    width: 90% !important;
  }
  .proImg {
    width: auto;
    height: 100px;

  }
  .proBox {
    padding: 0;
  }
  .el-aside {
    width: 100% !important;
  }
}
@media only screen and (max-width: 768px) {
  .el-container{
    display: block;
    padding: 0 5px;
  }
  .proImg {
    width: auto;
    height: 80px;
  }
  .proBox {
    padding: 0;

  }
  .proName {
    font-size: 20px;
  }
  .proDescription {
    font-size: 14px;
  }
  .el-aside {
    width: 100% !important;
  }
  ::v-deep .el-dialog {
    width: 90% !important;
  }
}

</style>
